import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import MemeSection from './components/Memesection';
import Footer from './components/Footer';
import Divider from './components/Divider';
import BabyCannonAnimation from './components/Cannon';



const App = () => {

  const logos  = [
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png',
    '/baby.png'

    // Add more image paths relative to the public folder here
  ];
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <Hero />
        <Divider logos={logos}/>
<BabyCannonAnimation />
<Divider logos={logos}/>

        <About />

        <MemeSection />
      </main>
      <Footer />
    </div>
  );
};

export default App;
