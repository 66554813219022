import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const BabyCannonAnimation = () => {
  const [fired, setFired] = useState(false);

  const handleFire = () => {
    setFired(true);
    setTimeout(() => setFired(false), 6000); // Reset after 6 seconds for demo
  };

  // Smoother arc using React Spring with slower motion and adjusted start position
  const springProps = useSpring({
    config: { tension: 50, friction: 60 }, // Lowered tension and increased friction for slower motion
    from: { x: 200, y: 200 }, // Start higher on Y and more to the right on X
    to: fired
      ? async (next) => {
          await next({ x: 2700, y: 1600 });
        }
      : { x: 400, y: -300 },
    reset: true,
  });

  return (
    <div
      className="relative overflow-hidden w-full h-[100vh]"
      style={{
        backgroundImage: "url('/field.jpeg')", // Set background image
        backgroundSize: 'cover', // Make sure the image covers the entire background
        backgroundPosition: 'center', // Center the image
      }}
    >
      {!fired ? (
        <img
          src="/babyincannon.png"
          alt="Baby in Cannon"
          className="absolute left-[150px] bottom-[100px] w-1/4" // Adjusted starting position
          onClick={handleFire}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <div className="relative w-full h-full">
          <img
            src="/cannon.png"
            alt="Cannon"
            className="absolute bottom-[100px] left-[100px] w-1/4" // Adjusted cannon position to match baby start
          />

          <animated.img
            src="/babyshot.png"
            alt="Baby"
            className="absolute w-1/6"
            style={{
              transform: springProps.x.interpolate(
                (x) => `translateX(${x}px)`
              ),
              bottom: springProps.y.interpolate((y) => `${y}px`),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BabyCannonAnimation;
