import React, { useState } from 'react';

const Hero = () => {
  const [copied, setCopied] = useState(false);

  const contractAddress = "6VSB9jmAkWM1BT3mEfbZ5YX6fwfuYNnANXx2Za3KNMBd";

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <section 
      id="home" 
      className="relative text-gray-800 p-8 text-center overflow-hidden" 
      style={{ backgroundColor: '#89bfe8' }}  // Set the background color here
    >
      <img 
        src="/limblessbaby.jpg" 
        alt="Limbless Baby" 
        className="mx-auto rounded-lg shadow-lg mb-4 w-3/4 sm:w-2/3 md:w-1/2 max-w-xs md:max-w-md border-4 border-black z-10" 
      />
      <h1 className="text-5xl sm:text-6xl font-display font-light mb-3 text-black-700 relative z-10">$LLB</h1>
      <p className="text-2xl sm:text-3xl font-body text-black relative z-10">Help Carry The Limbless Baby To The Moon</p>
      <div className="flex flex-wrap justify-center mt-8 space-x-2 sm:space-x-4 relative z-10">
        <a href="https://dexscreener.com/solana/6vsb9jmakwm1bt3mefbz5yx6fwfuynnanxx2za3knmbd" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/dexscreener.png" alt="Dexscreener" className="h-10 sm:h-12 rounded-lg" />
        </a>
        <a href="pumpfun.com" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/pumpfun.webp" alt="Raydium" className="h-10 sm:h-12 border-4 border-black rounded-lg" />
        </a>
        <a href="https://t.me/f" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/tg.svg" alt="Telegram" className="h-10 sm:h-12 rounded-lg" />
        </a>
        <a href="https://x.com/f" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/tw.svg" alt="Twitter" className="h-10 sm:h-12 border-4 border-black rounded-lg" />
        </a>
      </div>
      <div className="mt-8 relative z-10">
        <div 
          className="inline-block px-2 py-1 bg-gray-200 rounded-lg cursor-pointer border-4 border-black sm:px-4 sm:py-2" // Adjust padding for mobile
          onClick={handleCopy}
          style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
        >
          <span className="text-md sm:text-3xl font-bold text-black"> {/* Adjust font size for mobile */}
            {copied ? 'Copied!' : 'CA: ' + contractAddress}
          </span>
        </div>
      </div>
      
      {/* Background Images with animate-bounce class */}
      <img 
        src="/pups/knightbaby.png" 
        alt="Background Pup 1" 
        className="hidden sm:block absolute top-1/4 left-1/4 w-20 sm:w-28 animate-bounce" 
        style={{ animationDelay: '0s' }} 
      />
      <img 
        src="/pups/babyplant.png" 
        alt="Background Pup 2" 
        className="hidden sm:block absolute top-1/4 right-1/4 w-24 sm:w-32 rotate-12 animate-bounce" 
        style={{ animationDelay: '0.2s' }} 
      />
      <img 
        src="/pups/bucket.png" 
        alt="Background Pup 3" 
        className="hidden sm:block absolute bottom-1/4 left-1/4 w-24 sm:w-32 -rotate-6 animate-bounce" 
        style={{ animationDelay: '0.4s' }} 
      />
      <img 
        src="/pups/dolphin.webp" 
        alt="Background Pup 4" 
        className="hidden sm:block absolute bottom-1/4 right-1/4 w-32 sm:w-44 rotate-33 animate-bounce" 
        style={{ animationDelay: '0.6s' }} 
      />
      <img 
        src="/pups/glizzard.png" 
        alt="Background Pup 5" 
        className="hidden sm:block absolute top-1/2 left-1/3 w-20 sm:w-28 -rotate-12 animate-bounce" 
        style={{ animationDelay: '0.8s' }} 
      />
      
      <img 
        src="/pups/popcornbaby.png" 
        alt="Background Pup 6" 
        className="hidden sm:block absolute top-1/3 right-1/3 w-16 sm:w-24 rotate-6 animate-bounce" 
        style={{ animationDelay: '1s' }} 
      />
      <img 
        src="/pups/wafiki.webp" 
        alt="Background Pup 7" 
        className="hidden sm:block absolute top-1/2 right-1/3 w-16 sm:w-24 rotate-3 animate-bounce" 
        style={{ animationDelay: '1.2s' }} 
      />
    </section>
  );
};

export default Hero;
