import React from 'react';

const About = () => (
  <section id="about" className="bg-white p-8 text-center">
    <h2 className="text-2xl md:text-3xl font-display font-bold mb-4">The Ultimate Dunk</h2>
    <div className="w-full md:w-4/5 lg:w-3/5 mx-auto">
      <div className="mb-4">
        <img 
          src="/dunk.png" 
          alt="Dunking to Win" 
          className="mx-auto w-full md:w-4/5 lg:w-3/5"
        />
      </div>
      <p className="text-base md:text-xl lg:text-2xl font-body mb-4">
Win at all costs! Dunk the baby!      </p>

      <p className="text-base md:text-lg lg:text-2xl font-body">Dunk to win. Victory awaits.</p>
    </div>
  </section>
);

export default About;
